import { css, html, LitElement } from 'lit';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import '@bmg-web/bmg-alert';
import { amwaApplicationStyle } from '../amwa-application-style';
import { setCookie } from "../util/cookie-service";
import { goToLogonChoice, popUrlParams } from "../util/navigate";


const COOKIE_NAME_REGEX = /^(logonType|bmg-.*)$/;

class AccessProtectionPage extends ScopedElementsMixin(LitElement) {

    static get properties() {
        return {
            _showError: {
                type: Boolean,
                state: true,
            },
        };
    }

    static get styles() {
        return [
            amwaApplicationStyle,
            css`
                .alert-body {
                    margin-right: 20px;
                }
                .alert-body > h4 {
                    margin-top: 0;
                }
                .alert-body > p {
                    margin-bottom: 0;
                }
            `,
        ];
    }

    connectedCallback() {
        const urlParams = popUrlParams();
        if (urlParams?.size) {
            Array.from( urlParams )
                .filter(([name, value]) => COOKIE_NAME_REGEX.test( name ) && !!value)
                .forEach(([name, value]) => setCookie( name, value ));
            goToLogonChoice();
        } else {
            this._showError = true;
        }
        super.connectedCallback();
    }

    render() {
        return this._showError && html`
            <bmg-alert type="error">
                <div class="alert-body">
                    <h4>You are not allowed to access Megabank</h4>
                    <p>
                        You do not have access (yet). Follow the correct procedure to get access.
                        <br>
                        If this is unclear, please contact your service team.
                    </p>
                </div>
            </bmg-alert>
        `;
    }
}

customElements.define('access-protection-page', AccessProtectionPage);
